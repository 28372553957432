//this is the file with only wallet installed 

import React, { useEffect, useState } from 'react';


require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey); 



export const connectWallet = async () => {


    if (window.ethereum) {
      if (window.ethereum.chainId !== "0x1") {  //mainnet
      // if (window.ethereum.chainId !== "0x5") {  //rinkeby

        return {
            address: "",
            status: (<div className="af-class-error-message w-form-fail" style={{ display: "block" }}>
                <div>Please make sure <br></br> you're connected to <br></br> the Ethereum mainnet</div>
            </div>)
        };
    }
    try {
        const addressArray = await window.ethereum.request({
            method: "eth_requestAccounts",
        });
        const obj = {
            status: "",
            address: addressArray[0],
        };
        return obj;
    } catch (err) {
        return {
            address: "",
            status: (<div className="af-class-error-message w-form-fail" style={{ display: "block" }}>
                <div>Something went wrong, please make sure you're connected on the Ethereum mainnet
                </div>
            </div>)
        };
    }
} 
else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };
  export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "You are Connected to the Network",
          };
        } else {
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  //sign the transaction via Metamask
  

