import React from 'react';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import octo_logo from '../images/Octo-logo.png'
import {
  connectWallet,
  getCurrentWalletConnected,
} from "../utils/interact.js";
import { ethers, Contract, BigNumber } from 'ethers';


const Navbar = ({ toggle, accounts, setAccounts }) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");


  const isConnected = Boolean(accounts[0]);


  useEffect(async () => {
    const signer = (new ethers.providers.Web3Provider(window.ethereum)).getSigner()
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address)
    setStatus(status);

    // const { mintedVX } = await mintedVX();
    // console.log(mintedVX)
    // setPublicMinted(mintedVX);
    



}, []);



  async function connectAccount() {
    if (window.ethereum) {
        try {
             const accounts = await window.ethereum.request({
                 method: "eth_requestAccounts",
             });
             setAccounts(accounts);
        } catch(err) {
              console.log("error: ", err)
        }     
        window.ethereum.on('chainChanged', handleChainChanged);
        window.ethereum.on('accountsChanged', handleChainChanged);
        function handleChainChanged(_chainId) {
            window.location.reload();
        }
    }
  }
  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
      setStatus(walletResponse.status);
      setWallet(walletResponse.address);
    };


  return (
    <nav
      className='flex justify-between items-center h-24 pl-4 bg-octohedz text-white relative shadow-sm'
      role='navigation'
    >
      <Link to='/' className='octo-logo-s'>
        <img className='octo-logo-s' src={octo_logo} alt="OctoHedz"/>
      </Link>
     
                <button className='bg-octo-btn ml-60 px-6 py-2 text-2xl' id="login_eth" onClick={connectAccount}>
                        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
                </button>
                

          
      <div className='px-4 cursor-pointer md:hidden' onClick={toggle}>
        <svg
          className='w-8 h-8'
          fill='none'
          stroke='currentColor'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M4 6h16M4 12h16M4 18h16'
          />
        </svg>
      </div>
      <div className='pr-8 md:block  hidden'>
        <Link to='/mint' className='p-4 text-xl t-octohedz'>
          Mint
        </Link>
        {/* <Link to='/profile' className='p-4 text-xl t-octohedz'>
          Claim
        </Link> */}
      </div>
    </nav>
  );
};

export default Navbar;
