import React from 'react';
import Inkz from '../abi/INKzABI.json'
import Reloaded from '../abi/OctoHedzReloadedABI.json'
import OctoHedz from '../abi/OctoHedzABI.json'
import OctoVX from '../abi/OctoHedzVXABI.json'
import inkzLogo from '../images/INKZ_TOKEN.gif'
import octo_logo from '../images/Octo-logo.png'
import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
} from "../utils/interact.js";
import { ethers, Contract, BigNumber } from 'ethers';




const octoHedzAdrs = "0x6E5a65B5f9Dd7b1b08Ff212E210DCd642DE0db8B"
const reloadedAdrs = "0x6da4c631C6AD8bFd9D7845Fd093541BEBecE2f96"
const inkzAdrs = "0xd0c37Cda48Fe5D6Ee7132181a90DC58206Db5404"
const octoHedzVXAdrs = "0xa7aA345FfaCdaD6A7d2633C8A10897c3D46b49F7"


var getClaimableTokens;
var InkzBalance;

var GENOCTO = [];

var OCTOClaim = [];

const provider = new ethers.providers.Web3Provider(window.ethereum);
// get user
const signer = provider.getSigner();

// Retrieve Contracts
const inkzContract = new ethers.Contract(inkzAdrs, Inkz.abi, signer);
const octoHedzContract = new ethers.Contract(octoHedzAdrs, OctoHedz.abi, signer);
const reloadedContract = new ethers.Contract(reloadedAdrs, Reloaded.abi, signer);
const octoHedzVXContract = new ethers.Contract(octoHedzVXAdrs, OctoVX.abi, signer);



async function handleCheckGen() {
  let genID = parseInt(document.getElementById('genID').value);
  
  if (genID <= 0 || genID > 888 ) return;
  
  if (window.ethereum) {
    try {
      genID =genID -1;


      const GenCanClaimed = await octoHedzVXContract.mintedIDs(genID);


      let claimedDiv = document.getElementById("claimedDiv");
      if (GenCanClaimed == 1) {
        claimedDiv.innerHTML = "Claimed";
          
      } else {
        claimedDiv.innerHTML = "UnClaimed";
      }
      
    } catch(err) {
        console.log("error: ", err)
    }
  }
      
};

async function handleCheckRel() {
  let relID = parseInt(document.getElementById('relID').value);
  
  if (relID <= 0 || relID > 8001 ) return;
  
  if (window.ethereum) {
    try {

      const RelCanClaimed = await octoHedzVXContract.ReladedmintedIDs(relID);
      let claimedDiv = document.getElementById("claimedDiv1");
      if (RelCanClaimed == 1) {
        claimedDiv.innerHTML = "Claimed";
          
      } else {
        claimedDiv.innerHTML = "UnClaimed";
      }
      
    } catch(err) {
        console.log("error: ", err)
    }
  }
      
};



async function octoClaim() {
  let selectedNFTs = document.querySelectorAll('#nfts input');
  
  OCTOClaim = [];
  
  for(let i = 0; i < selectedNFTs.length; i++){
    if(selectedNFTs[i].checked){
        
        OCTOClaim.push(selectedNFTs[i].value)
    }
  }
  var token1 = OCTOClaim[0]
  console.log(token1)
  if (window.ethereum) {
      try {
          const response = await octoHedzVXContract.mintGen(token1);
          console.log('response: ', response);
      } catch(err) {
          console.log("error: ", err)
      }
  }
};

async function octoMintVX() {
  let selectedNFTs = document.querySelectorAll('#nfts input');
  
  OCTOClaim = [];
  
  
  for(let i = 0; i < selectedNFTs.length; i++){
    if(selectedNFTs[i].checked){
        
        OCTOClaim.push(selectedNFTs[i].value)
    }
  }
  var token1 = OCTOClaim[0]
  var token2 = OCTOClaim[1]
  console.log(token1)
  console.log(token2)
  if (window.ethereum) {
      try {
          const response = await octoHedzVXContract.mintRel(token1, token2);
          console.log('response: ', response);
      } catch(err) {
          console.log("error: ", err)
      }
  }
};



async function octosOfOwner() {
  if (window.ethereum) {
    try {
      let userAdrs = await signer.getAddress();
      let octos = (await octoHedzContract.tokensOfOwner(userAdrs)).toString();
      GENOCTO = octos;
    } catch(err) {
          console.log("error: ", err)
    }     
  }

}
octosOfOwner();

async function claimTokens() {
  if (window.ethereum) {
    try {
        const response = await inkzContract.claimReward({
            value: ethers.utils.parseEther((0).toString()),
        });
        console.log('response: ', response);
        } catch(err) {
            console.log("error: ", err)
          }
  }
}

async function loadNFTs() {

  if (window.ethereum) {
    try {
      let userAdrs = await signer.getAddress();
      const OctoHedzBalance = (await octoHedzContract.balanceOf(userAdrs)).toString();
      const ReloadedBalance = (await reloadedContract.balanceOf(userAdrs)).toString();
      const octozCanClaim = (await octoHedzVXContract.claimableVX(userAdrs)).toString();
      const reloadedCanUse = (await octoHedzVXContract.unusedReloaded(userAdrs)).toString();
      let arrayUnused = reloadedCanUse.toString().split(',');
      let arrayClaim = octozCanClaim.toString().split(',');
      console.log(octozCanClaim)
      console.log(OctoHedzBalance)
      console.log(ReloadedBalance)
    
      for(let i = 0; i < OctoHedzBalance; i++) {
        const tokenId = (await octoHedzContract.tokenOfOwnerByIndex(userAdrs, i)).toString();
        let tokenMetadataURI = (await octoHedzContract.tokenURI(tokenId)).toString();
    
        if (tokenMetadataURI.startsWith("https://")) {
          tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI.split("https://ipfs.io/ipfs/")[1]}`
          
        }
    
      
        const tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json())
        const nftTokenElement = document.getElementById("nft_template").content.cloneNode(true)
        nftTokenElement.querySelector("h1").innerText = "OctoHedz #"+ (parseInt(tokenId)+1);
        nftTokenElement.querySelector("a").href = `https://opensea.io/assets/0x6e5a65b5f9dd7b1b08ff212e210dcd642de0db8b/${tokenId}`
        nftTokenElement.querySelector("img").src = tokenMetadata["image"]
        nftTokenElement.querySelector("label").style.display  = `none`
        for(let y = 0; y < arrayClaim.length; y++) {
          console.log(arrayClaim[y]+" - "+tokenId);
            if (arrayClaim[y]===tokenId) {
                nftTokenElement.querySelector("input").value = `${tokenId}`
                nftTokenElement.querySelector("input").id = `OctoHedzToken-${tokenId}`
                nftTokenElement.querySelector("label").style.display  = "block"
            }
        }
        
        document.getElementById("nfts").appendChild(nftTokenElement);
     
      }
    
      for(let i = 0; i < ReloadedBalance; i++) {
        const tokenId = (await reloadedContract.tokenOfOwnerByIndex(userAdrs, i)).toString();
        let tokenMetadataURI = (await reloadedContract.tokenURI(tokenId)).toString();
        if (tokenMetadataURI.startsWith("https://")) {
          tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI.split("https://ipfs.io/ipfs/")[1]}`
          
        }
    
      
        const tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json())
        const nftTokenElement = document.getElementById("nft_template_2").content.cloneNode(true)
        nftTokenElement.querySelector("h1").innerText = "Reloaded #"+ (parseInt(tokenId));
        nftTokenElement.querySelector("a").href = `https://opensea.io/assets/0x6da4c631c6ad8bfd9d7845fd093541bebece2f96/${tokenId}`
        nftTokenElement.querySelector("img").src = tokenMetadata["image"]
        nftTokenElement.querySelector("label").style.display  = `none`
        for(let y = 0; y < arrayUnused.length; y++) {
          console.log(arrayUnused[y]+" - "+tokenId);
            if (arrayUnused[y]===tokenId) {
                nftTokenElement.querySelector("input").value = `${tokenId}`
                nftTokenElement.querySelector("input").id = `ReloadedToken-${tokenId}`
                nftTokenElement.querySelector("label").style.display  = "block"
            }
        }
        
        document.getElementById("nfts").appendChild(nftTokenElement);
     
      }
    } catch(err) {
          console.log("error: ", err)
    }     
  }
}


const Profile = () => {

  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [claim, setClaim] = useState(0);
  const [inventory, setInventory] = useState(0);
  const [clickable, setClickable] = useState(false)

  useEffect(async () => {

    const {address, status} = await getCurrentWalletConnected();
    const signer = (new ethers.providers.Web3Provider(window.ethereum)).getSigner()
    setWallet(address)
    setStatus(status);
      if (inkzAdrs) {
        let inkzBalance = ethers.utils.formatEther(await getinkzBalance(address))
        let inventoryTokens = ethers.utils.formatEther(await getInventoryTokens(address))
        setClaim(inkzBalance)
        setInventory(inventoryTokens)
        // if (inkzBalance > 0) {
        //   setClickable(true);
        // }

      }
      
    
    
    }, []);


    async function getinkzBalance(userAdrs) {
      let tokenCount = 0;
      if (window.ethereum) {
        try {
             let userAdrs = await signer.getAddress();
             let tokenCount = await inkzContract.getTotalClaimable(userAdrs);
             tokenCount = ethers.utils.formatUnits(tokenCount, 18);
             getClaimableTokens = parseInt(tokenCount);
        } catch(err) {
              console.log("error: ", err)
        }     
      }
      return tokenCount;
    }

    async function getInventoryTokens(userAdrs) {
      let tokenCount = 0;
      if (window.ethereum) {
        try {
             let userAdrs = await signer.getAddress();
             let tokenCount = await inkzContract.balanceOf(userAdrs);
             tokenCount = ethers.utils.formatUnits(tokenCount, 18);
             InkzBalance = parseInt(tokenCount);
        } catch(err) {
              console.log("error: ", err)
        }     
      }
      return tokenCount;
    }


const truncateDecimals = (number, digits) => {
  var multiplier = Math.pow(10, digits),
    adjustedNum = number * multiplier,
    truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return truncatedNum / multiplier;
};
  
  return (





    <div className='flex flex-col justify-center bg-octohedz mb-20'>
      <div className='flex justify-between'>
      <div className='flex flex-col items-center justify-center bg-octohedz-d p-4 claim-margin'>
                   <div className='claim-sub'>Check Genesis ID</div>
                   <input id="genID" className='text-l p-2 text-black claim-input' type="number" placeholder="Gen #"/>
                   <button className='bg-octo-btn hover:bg-octo-btn text-white py-2 px-4 text-l mx-2 btn-margin' onClick={handleCheckGen}>
                            Check
                   </button>
                   <div className='mb-2 claim-status' id='claimedDiv'></div>
                   
        </div>
              
              
                
        <div className='flex flex-col items-center justify-center bg-octohedz-d p-10 claim-margin'>
          <div className='flex flex-col'>
              <p className='t-octohedz mb-2'>Wallet Balance</p>
          </div>
          <div className='flex items-center mb-4'>
          <h1 className='t-octohedz text-l text-center px-2'>{InkzBalance}</h1>
          <img className='INKz-tiny' src={inkzLogo} alt='INKz gif' />
          </div>
          <div>
            <button className='bg-octo-btn hover:bg-octo-btn text-white py-2 px-4 text-l mx-2' onClick={claimTokens}>Claim {truncateDecimals(getClaimableTokens, 2)} $INKz</button>
            
          </div>
        </div>

        <div className='flex flex-col items-center justify-center bg-octohedz-d p-4 claim-margin'>
                   <div className='claim-sub'>Check Reloaded ID </div>
                   <input id="relID" className='text-l p-2 text-black claim-input' type="number" placeholder="Rel #"/>
                   <button className='bg-octo-btn hover:bg-octo-btn text-white py-2 px-4 text-l mx-2 btn-margin' onClick={handleCheckRel}>
                            Check
                   </button>
                   <div className='mb-2 claim-status' id='claimedDiv1'></div>
                   
        </div>
      </div>
      
      <div className='rounded-lg bg-octohedz-d m-2'>
          <div className='flex items-center justify-center'>
            <button className='bg-octo-btn hover:bg-octo-btn text-white py-2 px-4 text-l btn-margin' onClick={loadNFTs}>Load OctoHedz</button>
          </div>

          <div className='flex items-center justify-center'>
          <div className='flex items-center justify-center'>
                <p className='t-octohedz'>Claiming costs 300</p>
                <img className='INKz-tiny mx-1' src={inkzLogo} alt='INKz gif' />
            </div>
          </div>
        <div className='flex justify-center'>

          

          <div>
            <button className='bg-octo-btn hover:bg-octo-btn text-white py-2 px-4 text-l mx-2 btn-margin' onClick={octoClaim}>Genesis Claim</button>
          </div>

          <div>
            <button className='bg-octo-btn hover:bg-octo-btn text-white py-2 px-4 text-l btn-margin' onClick={octoMintVX}>Reloaded Claim</button>
          </div>
      
        
          </div>
          <div className='flex selectable'>
            <div id="nfts" className=" flex flex-wrap justify-center content-center items-center bg-octohedz-d p-10">
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
