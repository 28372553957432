import React from 'react';


const Footer = () => {
  return (
    <div className='footer flex justify-center items-center p-2 bg-octohedz-d'>
      <div className='flex'>

        <h1 className='p-4 text-xl'>
         SEE YOU IN THE METAVERSE
          
        </h1>

        <br></br>
        <h1 className='p-4 text-xl'>
             </h1>
        
      </div>


    </div>
    
  );
};

export default Footer;
